import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Logo, Button } from "../Ui";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Menu,
  Heart,
  ChevronRight,
  Gift,
} from "@styled-icons/boxicons-regular";
import { respondTo } from "../../theme/mixin";
import Avatar from "../Avatar/Avatar";

const HeaderSection = styled.header`
  position: absolute;
  left: auto;
  right: auto;
  z-index: 1000;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;

  ${respondTo.md`
    padding: 15px 0;
  `}
`;

const MenuWrapper = styled.div`
  display: flex;

  ${respondTo.md`
    margin-right: 15px;
  `}

  .menu-icons {
    position: relative;
    right: -15px;
    padding-left: 10px;
    padding-right: 15px;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;

    .menu-icon {
      padding: 0 5px;
      cursor: pointer;
      position: relative;

      ${respondTo.md`
        padding: 0 10px;
      `}

      .cart-numb {
        position: absolute;
        left: 5px;
        top: 7px;
        font-size: 11px;
        width: 16px;
        height: 16px;
        background-color: #e61274;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      a {
        color: #3a3c42;
      }

      .open-menu-box {
        position: relative;

        &:hover .menu-box {
          display: block;
        }

        .menu-box {
          display: none;
          position: absolute;
          top: 100%;
          right: -15px;
          padding-top: 30px;

          .menu-box-content {
            background-color: #fff;
            width: 230px;
            padding: 5px 0;
            border-radius: 10px;
            box-shadow: 0px 5px 15px #0000004c;

            &:after {
              content: "";
              position: absolute;
              box-shadow: #0000004c 1px 1px 2px;
              -moz-transform: rotate(225deg);
              -webkit-transform: rotate(225deg);
              top: 22px;
              right: 22px;
              border-width: 8px;
              border-style: solid;
              border-radius: 0 0 2px 0;
              border-color: transparent #fff #fff transparent;
            }

            ${respondTo.md`
              width: 300px;
            `}
          }

          li {
            border-bottom: 1px solid #e4e7f1;

            &:last-child {
              border-bottom: none;
            }

            a {
              position: relative;
              text-transform: uppercase;
              width: 100%;
              display: flex;
              padding: 5px 10px;
              justify-content: space-between;
              align-items: center;
              line-height: 0;

              &:hover {
                color: #eb5a3f;

                &:before {
                  content: "";
                  display: inline-block;
                  width: 5px;
                  height: 20px;
                  position: absolute;
                  background-color: #eb5a3f;
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .menu-user {
    z-index: 1;
    position: relative;

    .user-avatar {
      width: 60px;
      height: 60px;
      border: 2px solid #fff;
      background-color: #cccccc;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }

    &:hover .profile-box-content {
      display: block;
    }

    .profile-box-content {
      padding-top: 15px;
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
    }

    .profile-box {
      width: 300px;
      background-color: #fff;
      padding: 15px 10px;
      border-radius: 10px;
      box-shadow: 0px 5px 15px #0000004c;

      &:after {
        content: "";
        position: absolute;
        box-shadow: #0000004c 1px 1px 2px;
        -moz-transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
        top: 7px;
        right: 22px;
        border-width: 8px;
        border-style: solid;
        border-radius: 0 0 2px 0;
        border-color: transparent #fff #fff transparent;
      }

      & > span {
        font-weight: 300;
        display: block;
        color: #adb1bc;
      }

      & > strong {
        font-size: 16px;
        text-transform: uppercase;
      }

      a {
        text-transform: uppercase;
        color: #3a3c42;
      }

      .point {
        margin-top: 5px;
        padding-top: 5px;
        margin-bottom: 30px;
        border-top: 1px solid #e4e7f1;
        display: flex;
        align-items: center;
        color: #e61274;

        strong {
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
  }
`;

const MenuSection = styled.header`
  position: fixed;
  width: 100%;
  max-width: 1200px;
  left: auto;
  right: auto;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 10px;

  ${respondTo.md`
    padding-right: 0;
  `}
`;

const Header = (props) => {
  const { isLogged } = props;

  const userInfo = useSelector((state) => state.user.user.userinfo);
  const cart = useSelector((state) => state.user.user.cart);
  const checkIfPastTargetDate = () => {
    const targetDate = moment("2024-06-28T00:00:00");
    const now = moment();

    return now.isAfter(targetDate);
  };
  return (
    <div className="container">
      <HeaderSection>
        <HeaderContainer>
          <Logo logo={process.env.PUBLIC_URL + "/images/logo.png"} />
        </HeaderContainer>
      </HeaderSection>
      <MenuSection>
        {isLogged ? (
          <MenuWrapper>
            <div className="menu-icons">
              <div className="menu-icon">
                <Link to="/carrello">
                  {cart.length > 0 && (
                    <div className="cart-numb">{cart.length}</div>
                  )}
                  <img
                    style={{ width: 30, marginTop: 5 }}
                    src={process.env.PUBLIC_URL + "/icons/cart-icon.svg"}
                    alt=""
                  />
                </Link>
              </div>
              <div className="menu-icon">
                <Link to="/wishlist">
                  <Heart size={30} />
                </Link>
              </div>
              <div className="menu-icon">
                <div className="open-menu-box">
                  <Menu size={30} />
                  <div className="menu-box">
                    <div className="menu-box-content">
                      <ul>
                        <li>
                          <Link to="/">
                            <strong>Homepage</strong>
                            <ChevronRight size={25} />
                          </Link>
                        </li>
                        <li>
                          <Link to="/come-funziona">
                            <strong>Come funziona</strong>
                            <ChevronRight size={25} />
                          </Link>
                        </li>
                        <li>
                          <Link to="/area-partner">
                            <strong>Club +</strong>
                            <ChevronRight size={25} />
                          </Link>
                        </li>
                        <li>
                          <Link to="/catalogo">
                            <strong>Premiati di +</strong>
                            <ChevronRight size={25} />
                          </Link>
                        </li>

                        <li>
                          <Link to="/promo/vinciancoradipiu">
                            <strong>Vinci sempre di +</strong>
                            <ChevronRight size={25} />
                          </Link>
                        </li>

                        <li>
                          <Link to="/sondaggi/promo-survey">
                            <strong>Conosciamoci di +</strong>
                            <ChevronRight size={25} />
                          </Link>
                        </li>
                        <li>
                          <Link to="/faq">
                            <strong>Faq</strong>
                            <ChevronRight size={25} />
                          </Link>
                        </li>
                        <li>
                          <Link to="/contatti">
                            <strong>Contatti</strong>
                            <ChevronRight size={25} />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="menu-user">
              <Avatar size={60} border />

              <div className="profile-box-content">
                <div className="profile-box">
                  {userInfo && (
                    <>
                      <span>Ciao,</span>
                      <strong>{`${userInfo.firstname} ${userInfo.lastname}`}</strong>
                      <div className="point">
                        <Gift size={22} />
                        <strong>Hai {userInfo.score} Pt</strong>
                      </div>

                      <Link to="/user/profilo">
                        <strong>Vai al profilo</strong>
                        <ChevronRight size={25} />
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </MenuWrapper>
        ) : (
          <Link
            to={{
              pathname: "https://mylinkempage.linkem.com/MyLinkPlus",
            }}
            target="_blank"
          >
            <Button
              gradient={{ left: "#EB5A3F", right: "#FDCA46" }}
              upper
              style={{ marginRight: 10 }}
            >
              Accedi
            </Button>
          </Link>
        )}
      </MenuSection>
    </div>
  );
};

Header.propTypes = {
  toggleSidebar: PropTypes.func,
  logo: PropTypes.string,
  appInit: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
};

export default Header;
