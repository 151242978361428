import * as actionTypes from "./actionTypes";
import { sendLogError } from "./error";
import customaxios from "../../config/axios-refresh-token";
import { useHistory, Link } from "react-router-dom";
import { getUserInfo } from "../actions/index";
const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token,
    userId,
  };
};

const authError = (error) => {
  return {
    type: actionTypes.AUTH_ERROR,
    error,
  };
};

const firstLoginSuccess = (token) => {
  return {
    type: actionTypes.FIRST_LOGIN_SUCCESS,
    token,
  };
};

const firstLoginError = (error) => {
  return {
    type: actionTypes.FIRST_LOGIN_ERROR,
    error: error,
  };
};

export const clearFirstAccessError = () => {
  return {
    type: actionTypes.CLEAR_FIRST_LOGIN_ACCESS,
  };
};

export const authInit = (token) => {
  let endpoint = "auth/signinlinkem/";
  return (dispatch) => {
    customaxios
      .post(endpoint, { token })
      .then((result) => {
        dispatch(firstLoginSuccess(result.data.token));
        localStorage.setItem("token", result.data.token);
        dispatch(setRedirect());
      })
      .catch((error) => {
        dispatch(firstLoginError(error.response.data.error));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

export const fakeAuthInit = (token) => {
  let endpoint = `app/impersonate/${token}`;
  return (dispatch) => {
    customaxios
      .get(endpoint)
      .then((result) => {
        dispatch(firstLoginSuccess(result.data.token));
        localStorage.setItem("token", result.data.token);
        dispatch(setRedirect());
      })
      .catch((error) => {
        dispatch(firstLoginError(error.response.data.error));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const signinStart = () => {
  return {
    type: actionTypes.SIGNIN_START,
  };
};
const signinSuccess = (token, userId) => {
  return {
    type: actionTypes.SIGNIN_SUCCESS,
    token,
    userId,
  };
};
const signinError = (error) => {
  return {
    type: actionTypes.SIGNIN_ERROR,
    error,
  };
};
export const signinClearError = () => {
  return {
    type: actionTypes.SIGNIN_CLEAR_ERROR,
  };
};

export const signinInit = (username, password) => {
  let endpoint = "auth/signin";
  return (dispatch) => {
    dispatch(signinStart());
    customaxios
      .post("auth/signin", {
        username,
        password,
      })
      .then((result) => {
        dispatch(signinSuccess(result.data.token));
        localStorage.setItem("token", result.data.token);
        dispatch(setRedirect());
      })
      .catch((error) => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status >= 400) {
          if (status === 403) {
            dispatch(signinError(message));
          } else {
            dispatch(signinError(message));
          }
        }
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const signupStart = () => {
  return {
    type: actionTypes.SIGNUP_START,
  };
};

const signupSuccess = (data) => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    data,
  };
};

const signupError = (error) => {
  return {
    type: actionTypes.SIGNUP_ERROR,
    error,
  };
};

export const signupClearError = () => {
  return {
    type: actionTypes.SIGNUP_CLEAR_ERROR,
  };
};

export const signUpInit = (data) => {
  let endpoint = "auth/signup";
  return (dispatch) => {
    dispatch(signupStart());

    customaxios
      .post(endpoint, data)
      .then((result) => {
        dispatch(signupSuccess());
      })
      .catch((error) => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400) {
          dispatch(signupError(message));
        }
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

export const setRedirect = () => {
  return {
    type: actionTypes.SET_REDIRECT,
  };
};

export const setRedirectFalse = () => {
  return {
    type: actionTypes.SET_REDIRECT_FALSE,
  };
};

export const checkAuthState = () => {
  return (dispatch) => {
    dispatch(authStart());
    const token = localStorage.getItem("token");
    if (token && token !== "undefined") {
      dispatch(authSuccess(token));
    } else {
      dispatch(authError("user not logged"));
    }
  };
};

const authLogoutStart = () => {
  return {
    type: actionTypes.LOGOUT_START,
  };
};

export const authLogoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
  };
};
const authLogoutError = () => {
  return {
    type: actionTypes.LOGOUT_ERROR,
  };
};

export const logout = () => {
  let endpoint = "auth/signout";
  return (dispatch) => {
    dispatch(authLogoutStart());
    customaxios
      .get(endpoint)
      .then((result) => {
        dispatch(setRedirectFalse());
        dispatch(authLogoutSuccess());
        localStorage.removeItem("token");
      })
      .catch((error) => {
        dispatch(setRedirectFalse());
        localStorage.removeItem("token");
        dispatch(authLogoutError());
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const recoveryPasswordStart = () => {
  return {
    type: actionTypes.RECOVERY_PASSWORD_START,
  };
};
const recoveryPasswordSuccess = () => {
  return {
    type: actionTypes.RECOVERY_PASSWORD_SUCCESS,
  };
};
const recoveryPasswordError = (error) => {
  return {
    type: actionTypes.RECOVERY_PASSWORD_ERROR,
    error,
  };
};

export const recoveryPasswordClearError = () => {
  return {
    type: actionTypes.RECOVERY_PASSWORD_CLEAR_ERROR,
  };
};

export const recoveryPassword = (email) => {
  let endpoint = "auth/forgot/recovery";
  return (dispatch) => {
    dispatch(recoveryPasswordStart());
    customaxios
      .post(endpoint, {
        email,
      })
      .then((result) => {
        dispatch(recoveryPasswordSuccess());
      })
      .catch((error) => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400) {
          if (
            message === "User not found" ||
            message === "User not related to this application"
          ) {
            dispatch(recoveryPasswordError(message));
          } else {
            dispatch(recoveryPasswordError(message));
          }
        }
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const resetPasswordStart = () => {
  return {
    type: actionTypes.RESET_PASSWORD_START,
  };
};

const resetPasswordSuccess = () => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
  };
};

const resetPasswordError = (error) => {
  return {
    type: actionTypes.RESET_PASSWORD_ERROR,
    error,
  };
};

export const resetPasswordClearError = () => {
  return {
    type: actionTypes.RESET_PASSWORD_CLEAR_ERROR,
  };
};

export const resetPassword = (onetimetoken) => {
  let endpoint = `auth/forgot/reset/${onetimetoken}`; // password forgot
  return (dispatch) => {
    dispatch(resetPasswordStart());
    customaxios
      .get(endpoint)
      .then((result) => {
        dispatch(resetPasswordSuccess());
      })
      .catch((error) => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400 || status === 422) {
          if (message === "User not related to this application") {
            dispatch(resetPasswordError(message));
          } else {
            dispatch(resetPasswordError(message));
          }
        }
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const changePasswordStart = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_START,
  };
};

const changePasswordSuccess = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
  };
};

const changePasswordError = (error) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_ERROR,
    error,
  };
};

export const changePasswordClearError = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_CLEAR_ERROR,
  };
};

export const changePassword = (password, confirm, onetimetoken) => {
  let endpoint = `auth/forgot/reset`; // password reset
  return (dispatch) => {
    dispatch(changePasswordStart());
    customaxios
      .post(endpoint, {
        password,
        confirm,
        onetimetoken,
      })
      .then((result) => {
        dispatch(changePasswordSuccess());
      })
      .catch((error) => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400) {
          if (message === "User not related to this application") {
            dispatch(changePasswordError(message));
          } else {
            dispatch(changePasswordError(message));
          }
        }
        if (status === 422) {
          if (message === "Password and confirm password are not the same") {
            dispatch(changePasswordError(message));
          }
        }
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const checkAuthStart = () => {
  return {
    type: actionTypes.CHECK_AUTH_START,
  };
};

const checkAuthSuccess = () => {
  return {
    type: actionTypes.CHECK_AUTH_SUCCESS,
  };
};

const checkAuthError = () => {
  return {
    type: actionTypes.CHECK_AUTH_ERROR,
  };
};

export const checkAuth = () => {
  let endpoint = `auth/check`;
  return (dispatch) => {
    dispatch(checkAuthStart());
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(checkAuthSuccess());
          resolve();
        })
        .catch((error) => {
          dispatch(checkAuthError());
          resolve(error);
          if (error.response.status >= 500) {
            dispatch(sendLogError(endpoint, error));
          }
        });
    });
  };
};

const oauthSendTokenStart = () => {
  return {
    type: actionTypes.OAUTH_SEND_TOKEN_START,
  };
};

const oauthSendTokenSuccess = (token) => {
  return {
    type: actionTypes.OAUTH_SEND_TOKEN_SUCCESS,
    token,
  };
};

const oauthSendTokenError = (error) => {
  return {
    type: actionTypes.OAUTH_SEND_TOKEN_ERROR,
    error,
  };
};

export const oauthSendToken = (oauthtoken, oauthprovider, data) => {
  let endpoint = `/auth/oauth`; // oauth signin
  return (dispatch) => {
    dispatch(oauthSendTokenStart());
    //dispatch(authStart());
    customaxios
      .post(endpoint, {
        oauthtoken,
        oauthprovider,
        oauthdata: JSON.stringify(data),
      })
      .then((result) => {
        dispatch(oauthSendTokenSuccess(result.data.token));
        //dispatch(authSuccess(result.data.token));
        localStorage.setItem("token", result.data.token);
        //dispatch(setRedirect()); // forse si o forse no
        dispatch(setRedirect()); // ora funziona per fb e google e stardust
      })
      .catch((error) => {
        dispatch(oauthSendTokenError(error.response.status));
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const activateUserSuccess = () => {
  return {
    type: actionTypes.ACTIVATE_USER_SUCCESS,
  };
};

export const clearActivateUser = () => {
  return {
    type: actionTypes.CLEAR_ACTIVATE_USER,
  };
};

const activateUserStart = () => {
  return {
    type: actionTypes.ACTIVATE_USER_START,
  };
};

export const activateUser = (accept_launch_contest_rules_23, data) => {
  let endpoint = "auth/me/activate";
  return (dispatch) => {
    dispatch(activateUserStart());

    customaxios
      .post(endpoint, { accept_launch_contest_rules_23 })
      .then((result) => {
        if (result.data.user.first_access_done) {
          dispatch(activateUserSuccess());
          if (data?.accept_launch_contest_rules_24 == 1) {
            dispatch(acceptConsent("accept_launch_contest_rules_24"));
          }
        }
      })
      .catch((error) => {
        dispatch(authError("Dati errati"));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

export const acceptConsent = (consent) => {
  let endpoint = `auth/me/consent`;

  return (dispatch) => {
    customaxios
      .post(endpoint, { consent_name: consent })
      .then((result) => {
        dispatch(getUserInfo());
        dispatch(activateUserSuccess());
      })
      .catch((error) => {});
  };
};
