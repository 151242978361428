import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import { ChevronUp } from "@styled-icons/boxicons-regular";
import moment from "moment";
import { checkIfPastTargetDate } from "../../utils/utils";
const FooterWrapper = styled.div`
  margin-bottom: 30px;
  text-align: center;
  padding: 0 15px;

  ${respondTo.md`
    margin-bottom: 70px;
    padding: 0;
  `}

  .legal-notes {
    margin-top: 10px;
    font-size: 8px;
    color: #adb1bc;

    a {
      font-weight: bold;
      color: #eb5a3f;
      text-decoration: underline;
    }
  }
`;

const FooterContainer = styled.footer`
  overflow: hidden;
  background-color: #3a3c42;
  flex-shrink: 0;
  border-radius: 15px;
  position: relative;
  padding-bottom: 15px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15px;
    background: transparent linear-gradient(90deg, #eb5a3f 0%, #fdca46 100%) 0%
      0% no-repeat padding-box;
  }
`;

const MainBar = styled.div`
  padding: 20px;

  .MainBarContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    ${respondTo.md`
      flex-wrap: nowrap;
    `}

    .logo-footer {
      max-width: 150px;
    }

    .MainBarText {
      color: #fff;
      text-align: center;
      font-size: 12px;
      margin-top: 20px;

      .links {
        margin-top: 2px;
        text-align: center;

        a {
          color: #eb5a3f;
          text-decoration: underline;
          font-size: 10px;
          margin: 0 7px;
        }
      }

      ${respondTo.md`
        margin-top: 0;
      `}
    }

    .MainBarRight {
      color: #eb5a3f;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      position: relative;
      top: 5px;
      margin: 15px auto 0 auto;

      ${respondTo.md`
        margin: 0;
      `}
    }
  }
`;

const Footer = (props) => {
  return (
    <div className="container">
      <FooterWrapper>
        <FooterContainer>
          <MainBar>
            <div className="MainBarContent">
              <img
                src={process.env.PUBLIC_URL + "/images/logo-white.png"}
                alt=""
              />
              <div className="MainBarText">
                © 2024 Tiscali Italia S.p.A. - All rights reserved - P. IVA
                02508100928
                <div className="links">
                  <Link
                    to={process.env.PUBLIC_URL + "/pdf/informativa-privacy.pdf"}
                    target="_blank"
                  >
                    Privacy
                  </Link>
                  <Link
                    to={process.env.PUBLIC_URL + "/pdf/TerminiDiServizio.pdf"}
                    target="_blank"
                  >
                    Termini di servizio
                  </Link>
                  <Link
                    to={
                      process.env.PUBLIC_URL +
                      "/pdf/Regolamento_OP_MyLinkPlus_2021-2022-2023-2024-2025.pdf"
                    }
                    target="_blank"
                  >
                    {" "}
                    Regolamento MyLinkPlus 2021/2022/2023/2024/2025
                  </Link>

                  {/* <Link
                    to={
                      process.env.PUBLIC_URL +
                      "/pdf/regolamento-vinci-di-piu.pdf"
                    }
                    target="_blank"
                  >
                    Regolamento Vinci di +
                  </Link> */}
                  {/* <Link
                    to={
                      process.env.PUBLIC_URL +
                      "/pdf/regolamento_vinci-molto-di-piu.pdf"
                    }
                    target="_blank"
                  >
                    Regolamento Vinci (molto) di +
                  </Link> */}
                  <Link
                    to={
                      process.env.PUBLIC_URL +
                      "/pdf/Regolamento_Linkem_Vinci_ancora_di_più.pdf"
                    }
                    target="_blank"
                  >
                    Regolamento Vinci ancora di +
                  </Link>
                  {checkIfPastTargetDate("2024-07-22T00:00:00") ? (
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        "/pdf/2024_TISCALI-VINCI_SEMPRE_DI_PIU_con_rushwin_instant_win_rev privacy_04-07-2024_DEF.pdf"
                      }
                      target="_blank"
                    >
                      Regolamento Vinci sempre di più
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div
                className="MainBarRight"
                onClick={() => window.scrollTo(0, 0)}
              >
                Torna in cima <ChevronUp size={30} />
              </div>
            </div>
          </MainBar>
        </FooterContainer>
        <p className="legal-notes">
          MyLinkPlus 2021/2022/2023/2024/2025 operazione a premi valida dal 6
          luglio 2021 al 30 giugno 2025, con termine richiesta premi entro il 15
          luglio 2025. Per maggiori dettagli consulta il{" "}
          <Link
            to={
              process.env.PUBLIC_URL +
              "/pdf/Regolamento_OP_MyLinkPlus_2021-2022-2023-2024-2025.pdf"
            }
            target="_blank"
          >
            regolamento
          </Link>
          .
        </p>
      </FooterWrapper>
    </div>
  );
};

Footer.propTypes = {
  footercontent: PropTypes.array,
};

export default Footer;
